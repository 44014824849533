import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

import Thumbnail from "../thumbnail"
import BuyButtonBar from '../ui/buybuttonbar'

import BookDetail from "../bookdetail"
import Recommenders from "../recommenders"

import { hyphenatedName } from '../../services/format'

function copyToBookObj(topbook){
    //const title = topbook.hasOwnProperty('_id') ? topbook._id[0] : topbook.title
    const book = Object.assign({}, 
        {title: topbook.title[0], 
        affiliateLink: topbook.affiliate_link[0],
        geniusLink: topbook.geniusLink[0],
        indieboundLink: topbook.indieboundLink[0],
        thumbnail: topbook.thumbnail[0],
        genre: topbook.genre[0],
        recommendors: topbook.recommendors,
        number_of_recommendors: topbook.number_of_recommendors
    })
    return book
}

function TopBooks({topbooks}){
    return(
        <ul style={{ padding: 0 }}>
            {topbooks.map((topbook,index) =>{
                const book = copyToBookObj(topbook.node)
                if(!book.title){
                    console.log('Check Book has no title')
                    return
                }
                const titleUnderscore = hyphenatedName(book.title)
                const recoLink = '/recos/' + titleUnderscore
                
                return (
                        <li
                        key={book.id}
                        style={{
                            textAlign: "center",
                            listStyle: "none",
                            display: "block"
                        }}
                        >
                            <Card style = {{marginBottom: "30px"}}> 
                                <Card.Body>
                                    <Row>
                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={5} sm={5} md={3} lg={2}>
                                                    <Link to={recoLink}><Thumbnail thumbnail={ book.thumbnail } alt={book.title}/></Link>
                                                </Col>
                                                <Col xs={7}>
                                                    <Card.Title>
                                                        <BookDetail book={ book } recoLink = { recoLink }/>
                                                    </Card.Title>
                                                    <BuyButtonBar book={ book }/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="footer">
                                    <Recommenders list = {book.recommendors} booktitle = {titleUnderscore}/>
                                </Card.Footer>   
                            </Card>
                        </li>            
                )}
            )}
        </ul>
    )
}

export default TopBooks;