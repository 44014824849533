import React from 'react';
import { Link } from 'gatsby';

import './style.css'

function Recommenders({list, booktitle}){
    return (
        <div className="footertxt">
            <span className="space">Recommended by</span>

            {list.map((recommender, index, arr) => {
                const user_name = recommender.user_name.toLowerCase().split(' ').join('-')
                const reco_ref = user_name.concat('#').concat(recommender.reco_tweet).concat('_').concat(booktitle)

                return(
                    <span key={index} className="space">
                        <Link to= {"/books/".concat(reco_ref)} target="_blank">
                        {recommender.user_name} 
                        </Link>
                        { index === (arr.length-1) ? ' ' : ',' }
                    </span>
                )
            }
            )}
        </div>
    )
}

export default Recommenders;