import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TopBooks from "../components/topbooks"

import { TopbooksT } from "../services/seotemplate"

import '../styles/global.css'

const TopBooksPage = ({ data }) => {
  const title = TopbooksT.title
  const description = TopbooksT.description

  const schema = 
  {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": title,
    "description": description
  }

  return(
  <Layout>
    <SEO title={title} description={description} schemaMarkup={schema} />
    <h1 className="heading">{title}</h1>
    <TopBooks topbooks={data.allTopBook.edges} />
  </Layout>
  )
}


export const query = graphql`
  {
    allTopBook {
      edges {
        node {
          id
          title: _id
          affiliate_link
          geniusLink
          indieboundLink
          thumbnail
          genre
          recommendors {
            reco_tweet
            user_name
            user_screen_name
          }
          number_of_recommendors
        }
      }
    }
  }
`

export default TopBooksPage;