import React from 'react'
import { Link } from 'gatsby';
import './style.css'

import { formatName } from '../../services/format'

function BookDetail({book, recoLink}){
    const author = formatName(book.author)

    return(
        <div className="detail">
            <Link to={recoLink}><h2 className="book-title">{book.title}</h2></Link>
            { author ?<h3 className="author">{author}</h3>: null }
        </div>
    )
}

export default BookDetail;